import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import jwvansickle from "../images/jwvansickle.jpg"
import { Link } from "gatsby"

let fontColorString = "rgb(90, 90, 90)"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Intro"
      description="View the personal website of John VanSickle. This portfolio site will provide an introduction into John VanSickle's personal and professional software development projects. You will find recent technologies, personal projects, professinal experience, and contact details."
    />

    <div className="row mt-3">
      <div className="col text-center">
        <img
          src={jwvansickle}
          style={{
            width: "150px",
            borderRadius: "50%",
            boxShadow: `5px 5px 10px ${fontColorString}`,
          }}
        />

        <h3
          className="mt-2"
          style={{
            textAlign: "center",
            fontSize: 30,
            color: fontColorString,
          }}
        >
          John VanSickle
        </h3>

        <p style={{ fontSize: "14", color: fontColorString }}>
          I'm a software developer with over {new Date().getFullYear() - 2014}{" "}
          years of experience.
        </p>

        <p style={{ fontSize: "14", color: fontColorString }}>
          Professionally, I’ve gotten to work on projects making desktop
          transcription software, a Chrome extension, a parking lot detection
          system, and numerous web applications. In my early career, I worked as
          a full stack web developer maintaining and improving roofing-oriented
          CRM software. I bring experience with numerous web and cloud
          technologies, and am always excited to learn something new.
        </p>

        <p style={{ fontSize: "14", color: fontColorString }}>
          Whenever I am not working, I will likely be found driving my antique
          Alfa Romeo, riding bikes, or doing something to spend time outside.
          It's always nice to spend a warm day out on the porch with the Green
          Mountain Grills Smoker cooking a big pork shoulder!
        </p>

        <p style={{ fontSize: "14", color: fontColorString }}>
          Check out my more recent <Link to="/technologies">technologies</Link>{" "}
          and <Link to="/projects">personal projects</Link>.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
